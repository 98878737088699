<style lang="scss">
.books-hub {
  background-color: #EDEDED;
}
</style>

<template>
    <section class="mb-10 books-hub">
        <query-books key="popular" title="Popular" :filter="{tag: 'popular'}" @result="viewBook($event)"></query-books>
        <visual-search @result="viewBook($event)"></visual-search>
        <we-recommend :filter="{tag: 'recommended', limit: 5}" @result="viewBook($event)"></we-recommend>
    </section>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { mapState } from 'vuex';
  import QueryBooks from '../components/magSliders/QueryBooks';
  import VisualSearch from '../components/search/VisualSearch.vue';
  import WeRecommend from '../components/recommendBlock/WeRecommend.vue';

  export default {
    props: [''],
    components: {
      QueryBooks,
      VisualSearch,
      WeRecommend,
    },
    computed: mapState({}),
    data() {
      return {
        activeMag: null,
      };
    },
    mounted() {
      this.$store.commit('SET_HIDE_UI', false);
    },
    methods: {
      viewBook(book) {
        this.$router.push({name: 'book-view', params: { bookId: book.bookId }});
      },
      sendLog(description, meta = null) {
        this.$store.dispatch('sendLog', {
          description: description,
          meta: meta
        });
      },
    },
  };
</script>
