<style lang="scss">
.visual-search {
  .search-item {
    background-color: white;
    border: 1px solid $border-color;
  }

  &__search {
    border: 1px solid $border-color;
    border-radius: 2em;

    .v-input__slot {
      background-color: white !important;
      input {
        color: black !important;
      }
    }
  }

  #visual-search-results { // need id to overwrite vuetify
    border: 1px solid $border-color !important;
  }

  &__results {
    border-radius: 1em;

    &__item {
      border-bottom: 1px solid $border-color;
      padding-bottom: 15px;

      &:hover {
        background-color: #D3D3D377;
      }

      &:not(:nth-child(1)) {
        padding-top: 15px;
      }
    }

    .res-img {
      flex-basis: 175px;

      img {
        width: 150px;
        height: 100%;
        object-fit: cover;
      }
    }

    .res-details {
      flex-basis: 51%;
    }

    .res-btn {
      flex-basis: 33%;

      button {
        font-size: 18pt;
      }
    }
  }

}
</style>

<template>
  <section class="visual-search max-content-width" id="visual-search">
    <div class="pa-6">
      <section class="filters">
        <section class="d-flex justify-end align-center mb-4">
          <div class="ui-spacer"></div>
          <v-autocomplete
              @change="searchForBooks(true, 1)"
              solo
              class="search-item mr-4"
              label="Enter a language"
              clearable
              v-model="filters.language"
              item-text="text"
              item-value="value"
              :items="languages">
          </v-autocomplete>

          <v-autocomplete
              @change="searchForBooks(true, 1)"
              solo
              label="Enter a genre"
              class="search-item"
              clearable
              v-model="filters.genre"
              item-text="text"
              item-value="value"
              :items="genres">
          </v-autocomplete>
        </section>

        <v-text-field
            @change="searchForBooks(true, 1)"
            class="mb-1 visual-search__search"
            v-model="filters.title"
            clearable
            flat
            solo-inverted
            hide-details
            append-icon="mdi-magnify"
            label="Search for a book"
        ></v-text-field>
      </section>

      <p class="text-right mt-4">Showing {{books.length * currentPage}} of {{count}} books</p>

      <section id="visual-search-results" class="visual-search__results pa-8 white">
        <section class="visual-search__results__item d-flex" v-for="book in books" :key="book.bookId">
          <section class="pointer res-img" @click="$emit('result', book)">
            <img width="100%" height="auto" :src="book.imageUrl" @error="setBookPlaceholder($event)">
          </section>
          <section class="ml-4 res-details">
            <p class="title">{{book.title}}</p>
            <p>Authors: {{book.authors || 'Not known'}}</p>
            <p>Issued: {{book.issued || 'Not known'}}</p>
            <p>Subjects: {{book.subjects || 'None'}}</p>
          </section>
          <section class="res-btn d-flex align-center justify-center">
            <v-btn @click="$emit('result', book)" class="theme-btn" x-large elevation="0">Read Book</v-btn>
          </section>
        </section>
      </section>

      <v-pagination @input="searchForBooks" v-model="currentPage" :length="getPaginatedLength(count, filters.limit)" :total-visible="10" class="mt-4 left-pagination"></v-pagination>

      <div v-if="loading" class="d-flex justify-center pb-6 pt-15">
        <v-progress-circular :indeterminate="true" :rotate="0" :value="0" color="white"></v-progress-circular>
      </div>

    </div>
  </section>
</template>

<script>
import BookService from '../../services/BookService';
import * as Languages from '../languages/languages.json';
import HelperService from '../../services/HelperService';

export default {
  props: {},
  components: {},
  data() {
    return {
      languages: [],
      genres: [],
      books: [],
      loading: false,
      keys: [
        'name',
      ],
      timer: null,
      count: 0,
      currentPage: 1,
      filters: {
        limit: 10,
        title: '',
        genre: '',
        language: '',
        skip: 0,
      },
      placeholder: require('../../assets/images/books/placeholder.png')
    };
  },
  // watch: {
  //   filters: {
  //     deep: true,
  //     handler() {
  //       this.searchForBooks();
  //     }
  //   },
  // },
  computed: {
    filteredKeys() {
      return this.keys.filter(key => key !== 'name')
    },
  },
  mounted() {
    this.searchForBooks(false, 1);
    this.getLanguages();
    this.getGenres();
  },
  methods: {
    getPaginatedLength: HelperService.getPaginatedLength,
    getLanguages() {
      this.languages = Languages.map(item => {
        return {
          text: item.name,
          value: item.code,
          ...item
        }
      });
    },
    getGenres() {
      BookService.getGenres().then(res => {
        this.genres = res.data.map(item => {
          return {
            text: item,
            value: item,
          }
        });
      }).catch(() => {
        this.$toasted.error('There is a problem getting the books genres, please refresh and try again');
      })
    },
    setBookPlaceholder(event) {
      event.target.setAttribute('src', this.placeholder);
    },
    searchForBooks(log = true, page) {
      this.loading = true;
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.currentPage = page ? page : this.currentPage;

      this.timer = setTimeout(() => {
        this.performSearch(log, page);
      }, 1000);
    },
    getLogByFilter(filter) {
      let text = '';

      if (filter.title) {
        text += `title: ${filter.title} `;
      }

      if (filter.genre) {
        text += `genre: ${filter.genre} `;
      }

      return text;
    },
    performSearch(log = true) {
      this.loading = true;

      if (log) {
        this.sendLog(`Searched for book with query: ${this.getLogByFilter(this.filters)}`,
            {query: this.filters});
      }

      this.filters.skip = (this.currentPage - 1) * this.filters.limit;
      BookService.getBooks(this.filters).then(async (res) => {
        // console.log('got books', res.data);
        this.books = res.data.data;
        this.count = res.data.count;
      }).catch((err) => {
        this.$toasted.error('There is a problem with this book search, please search for another');
        console.log('could not get books', err);
      }).finally(() => {
        this.loading = false;
      })
    },
    sendLog(description, meta = null) {
      this.$store.dispatch('sendLog', {
        description: description,
        meta: meta
      });
    },
    setGenre(genre) {
      this.filters.genre = genre;
      this.searchForBooks(true);
    }
  },
};
</script>
