import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';

Vue.http = Vue.prototype.$http = axios;
Vue.use(VueRouter);

import Books from '../views/Books';
import BookView from '../views/BookView';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Books
  },
  {
    path: '/view/book/:bookId',
    name: 'book-view',
    component: BookView
  },
  {
    path: '/mdm',
    name: 'mdm',
    component: () => import('@/views/mdm/MdmSetup.vue'),
    meta: {
      headerHide: true
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router
