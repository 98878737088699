<style lang="scss">
.book-view{
  min-height: 100vh;
  background: white;

  #viewer {
    height: 100vh;
  }

  &__loader{
    height: 100vh;
    background: linear-gradient(to right, $theme-blue 0%,#a849a3 83%);
    p{
      font-size: 35pt
    }
  }

  .ui-toggle {
    @include positioningOffset(fixed, 0, 0, unset, unset);
    z-index: 10;
  }

  .book-menu {
    @include positioningOffset(fixed, 10%, unset, unset, 0);
    z-index: 2;
    font-size: 22pt;
    background-color: white;
    border: 1px solid lightgrey;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    box-shadow: $subtle-shadow;
  }

  .book-reader {
    &__contents {
      header {
        &.open {
          border-bottom: 2px solid lightgrey;
        }
      }

      &__chapters {
        display: none;
        max-height: 70vh;
        background-color: white;
        overflow-y: auto;
        text-align: left;

        &.show {
          display: block;
        }

        .chapter-title {
          border-bottom: 1px solid lightgrey;
        }
      }
    }

    .reader-iframe {
      height: 95vh;
    }
  }
}
</style>
<template>
  <div class="book-view theme-purple-background" :class="{'electron': isElectron}">
    <div v-if="!loading" class="book-reader">
      <v-switch
          class="ui-toggle"
          color="#4F506F"
          :value="!hideUi"
          input-value="true"
          @change="toggleUi"
      ></v-switch>
      <div class="body" v-if="!pdfFile">
        <div class="text-right book-menu pointer mb-0 pa-1 pr-4" v-if="!hideUi">
          <div class="book-reader__contents full-width">
            <header class="d-flex mb-0" :class="{'open px-4 pt-4': showContents}">
              <span @click="showContents = true">Contents</span>
              <span class="ui-spacer"></span>
              <span v-if="showContents" class="pointer" @click="showContents = false"><i class="fas fa-times"></i></span>
            </header>
            <section class="book-reader__contents__chapters pa-4" :class="{'show': showContents}">
              <p class="text-lg-body-1 mb-6">Choose a chapter</p>
              <p @click="loadPage(chapter.href)" class="pointer chapter-title text-lg-body-1"
                 v-for="chapter in chapters" :key="chapter.ref">{{chapter.label}}</p>
            </section>
          </div>
        </div>
        <section>
          <div id="viewer" ></div>
        </section>

        <div v-if="!hideUi" @click="previousPage()" id="prev" class="arrow"><i class="fa fa-chevron-left"></i></div>
        <div v-if="!hideUi" @click="nextPage()" id="next" class="arrow"><i class="fa fa-chevron-right"></i></div>
      </div>

      <iframe class="reader-iframe"
              v-if="pdfFile && book" :src="book.downloadUrl" frameborder="0" width="100%"></iframe>
    </div>


    <section class="book-view__loader d-flex justify-center flex-wrap py-8" v-else>
      <v-progress-circular :size="100" :indeterminate="true" :rotate="0" :value="0" color="white"></v-progress-circular>
      <p class="font-weight-bold text-center mt-4 white--text ml-8">Getting your book...</p>
    </section>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import BookService from '../services/BookService';
let ePub = require('epubjs-il/dist/epub.min');

export default {
  name: 'Book-view',
  props: {},
  components: {

  },
  data() {
    return {
      bookId: this.$route.params.bookId,
      loading: true,
      showContents: false,
      rendition: null,
      book: null,
      chapters: [],
      pdfFile: false,
    };
  },
  computed: {
    ...mapState({
      isElectron: state => state.isElectron,
      hideUi: state => state.hideUi,
    })
  },
  watch: {},
  mounted() {
    this.$store.commit('SET_HIDE_UI', false);
    this.interceptRequest();
    this.getBook();
  },
  methods: {
    interceptRequest() {
      const intercept = (urlmatch) => {
        const open = XMLHttpRequest.prototype.open;

        XMLHttpRequest.prototype.open = function(method, url, ...rest) {
          url = url.replace(urlmatch, '');
          return open.call(this, method, url, ...rest);
        };
      }

      intercept('file.epub'); // intercept all request containing this and remove
    },
    getBook() {
      BookService.getBook(this.bookId).then((res) => {
        this.book = res.data;

        this.sendLog(`Reading book ${this.book.title}`, {});

        const url = this.book.downloadUrl;

        if (url.includes('files.purplemdm.com')) {
          if(url.includes('.epub')) {
            this.showBook(this.book.downloadUrl + 'file.epub');
           // this.showBook(url);
          } else {
            this.pdfFile = true; // is a pdf, show in iframe
          }
        } else {
          this.showBook(this.book.downloadUrl);
        }
      }).catch(() => {
        this.$toasted.error('Could not get book, please choose another');
      }).finally(() => {
        this.loading = false;
      });
    },
    sendLog(description, meta = null) {
      this.$store.dispatch('sendLog', {
        description: description,
        meta: meta
      });
    },
    // eslint-disable-next-line no-unused-vars
    async showBook(link) {
      const book = ePub(link);
      // this.rendition = book.renderTo("viewer", { flow: "scrolled-doc", width: 600, height: 400});

      this.rendition = book.renderTo("viewer", {width: '100%', height: '100%'});
      this.rendition.display(0);

      this.setRenditionTheme();

      // this.rendition.hooks.content.register((contents) => {
      //   this.setRenditionTheme();
      // })

      book.loaded.navigation.then((toc) =>{
        this.chapters = toc.toc;
      });
    },
    setRenditionTheme() {
      this.rendition.themes.default({
        '::selection': {
          'background': 'rgba(0,0,255, 0.3)'
        },
        'a:hover': {
          'color': 'rgb(0,0,0)'
        }
      });
    },
    nextPage() {
      this.rendition.next();
    },
    previousPage() {
      this.rendition.prev();
    },
    loadPage(url) {
      this.showContents = false;
      this.rendition.display(url);
    },
    toggleUi() {
      this.showContents = false;
      this.$store.commit('SET_HIDE_UI', !this.hideUi);
      this.injectCssTheme();
    },
    /**
     * Currently when toggling, some refresh code wipes out the theme and
     * re-adding to the renderer doe snot work, this is current work around
     */
    injectCssTheme() {
      setTimeout(() => {
        const iframe = document.querySelector('iframe');
        if(iframe) {
          let doc = iframe.contentDocument;
          doc.body.innerHTML = doc.body.innerHTML + '<style>a:hover{color: black !important;}</style>';
        }
      }, 500);
    }
  },
}
</script>
