<style lang="scss">
.recommend-block {
  background-color: $theme-blue;
  $section-height: 400px;

  &__item {
    flex-basis: 50%;
  }

  &__img {
    background-size: cover;
    background-position: 0 50%;
    background-repeat: no-repeat;
    height: $section-height;

    p {
      @include centerContent();
    }
  }

  .swiper-container {
    height: $section-height;
    max-width: 600px;
    margin-left: auto;

    img {
      width: auto !important;
      object-fit: contain;
      height: 250px !important;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 1em;
    }

    .active-only {
      height: 80%;
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: white;
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background-color: darkgray;
        }
      }
    }
  }
}
</style>

<template>
    <section class="recommend-block d-flex full-width" id="recommend-block">
      <section class="recommend-block__item recommend-block__img p-relative" :style="{'backgroundImage' : 'url(' + background + ')'}">
        <section class="bg-overlay"></section>
        <p class="title-text white--text">We Recommend...</p>
      </section>
      <section class="recommend-block__item">
        <div :class="sliderId" class="swiper-container swiper-container-popular" v-if="books.length > 0">
          <div class="swiper-wrapper">
            <div v-for="book in books" :key="book.bookId"
                 @click="clickedBook($event, book)"
                 class="swiper-slide swiper-one-only"
                 @error="setBookPlaceholder($event)">
              <section class="">
                <p class="title-text white--text">{{book.title}}</p>
                <img width="auto" height="100%" class="pa-4 mx-auto" :src="book.imageUrl"
                     @error="setBookPlaceholder($event)">
              </section>
            </div>
          </div><div class="swiper-pagination"></div>
        </div>
      </section>
    </section>
</template>

<script>
  import BookService from '../../services/BookService';
  import HelperService from '../../services/HelperService';
  import Vue from 'vue';
  import Swiper from 'swiper';

  export default {
    props: {
      filter: {
        required: true,
        type: Object,
      },
    },
    components: {},
    data() {
      return {
        sliderId: '',
        loading: true,
        swiper: null,
        books: [],
        placeholder: require('../../assets/images/books/placeholder.png'),
        background: require('../../assets/images/background/book-bg-2.png'),
      };
    },
    mounted() {
      this.sliderId = HelperService.getRandomId(8);
      this.getBooksByFilter();
    },
    methods: {
      getBooksByFilter() {
        this.loading = true;
        BookService.getBooks(this.filter).then(async (res) => {
          this.books = res.data.data;
        }).finally(() => {
          this.loading = false;

          Vue.nextTick(() => {
            this.setUpSlides();
          });
        });
      },
      setBookPlaceholder(event) {
        event.target.setAttribute('src', this.placeholder);
      },
      setUpSlides() {
        this.swiper = new Swiper('.' + this.sliderId, {
          grabCursor: true,
          // centeredSlides: true,
          slidesPerView: 1,
          pagination: {
            el: '.swiper-pagination',
          },
        });

        setTimeout(() => {
          if (this.swiper) {
            this.swiper.update();
          }
        });
      },
      clickedBook(e, book) {
        if (!e.target.classList.contains('fa-heart')) {
          this.$emit('result', book);
        }
      },
    },
  };
</script>
