import Vue from 'vue';
import Vuex from 'vuex';
import themeService from '../services/themeService';
import StatService from '../services/StatService';
import axios from 'axios';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    darkMode: false,
    isElectron: false,
    hideUi: false,
  },
  mutations: {
    SET_DARK_MODE(state, value){
      state.darkMode = value;
      themeService.setDarkTheme(value);
    },
    SET_IS_ELECTRON(state, value) {
      state.isElectron = value;
    },
    SET_HIDE_UI(state, value) {
      state.hideUi = value;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    sendLog({state}, log) {
      // console.log('log', log);
      if(state.isElectron) {
        StatService.sendStat(log.meta || {}, log.description);
      }
    },
    // eslint-disable-next-line no-unused-vars
    setLocalUserToken({ commit }, token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
  },
  modules: {}
});

export default store;
