<style lang="scss">
.theme-purple-background {
  //background-image: url("./img/gradient.jpeg");
}
</style>

<template>
  <v-app class="light-theme" :class="{'header-closed': hideUi}">
    <app-header></app-header>
    <v-main class="theme-background">
      <v-container fluid class="no-gutters pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import AppHeader from './components/AppHeader.vue';
  import '@/assets/styles/styles.scss';
  import 'swiper/swiper-bundle.css';
  import {mapState} from 'vuex'; // import Swiper styles

  export default {
    name: 'App',
    components: {
      AppHeader,
    },
    data: () => ({}),
    computed: {
      ...mapState({
        hideUi: state => state.hideUi,
      })
    },
    mounted() {
      // listen for a custom android event
      window.addEventListener('androidMagazineSuccess', (data) => {
        if(data) {
          const url = JSON.parse(data.detail).url;
          this.$store.commit('SET_MAGAZINE_TOKEN', url);
        }
      });
    }
  };
</script>
