import Vue from 'vue'
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';

import router from './router';
import VModal from 'vue-js-modal';
import Toasted from 'vue-toasted';
import VueMq from 'vue-mq';

Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
Vue.config.productionTip = false;

Vue.use(Toasted, {
  theme: 'outline',
  duration: 6500,
});

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 450,
    md: 1250,
    lg: Infinity,
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
