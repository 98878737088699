import axios from 'axios';
import {REST_ENDPOINT} from '../../endpoints';
import HelperService from './HelperService';

const base = `${REST_ENDPOINT}books/`;
const bookApiKey = 'admin-5836e013-79df-4708-8cc3-d7f0ead17fdd'; // todo probably need this in .env file
axios.defaults.headers['X-API-KEY'] = `${bookApiKey}`;

export default {
  async getGenres() {
    return axios.get(`${base}genres`);
  },
  async getBook(bookId) {
    return axios.get(`${base}single/${bookId}`)
  },
  async getBooks(filters) {
    const query = HelperService.buildSearchQueryString(filters);
    return axios.get(`${base}paginated${query}`)
  },
};
