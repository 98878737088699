<style lang="scss">
.latest-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 40%;

  .swiper-container {
    height: 300px;
    max-width: 600px;
    margin-left: auto;

    .active-only {
      height: 80%;
    }
  }

  .no-favourites {
    font-size: 18pt;
  }
}
</style>

<template>
  <section class="latest-section theme-border-thick theme-purple-background p-relative" id="query-section"
           :style="{'backgroundImage' : 'url(' + background + ')'}">
    <section class="bg-overlay"></section>
    <div v-if="!loading" class="animate__animated animate__fadeIn animate__slow">
      <p class="latest-section__title white--text title-text px-6 pt-4 mb-0">{{ title }}</p>
      <div :class="sliderId" class="swiper-container swiper-container-popular" v-if="books.length > 0">
        <div class="swiper-wrapper">
          <div v-for="book in books" :key="book.bookId"
               @click="clickedBook($event, book)"
               class="swiper-slide"
               @error="setBookPlaceholder($event)">
            <div class="active-only full-width white"></div>
            <img width="100%" height="auto" class="fill-height pa-4" :src="book.imageUrl"
                 @error="setBookPlaceholder($event)">
          </div>
        </div>
        <!-- Add Pagination -->
        <!--            <div class="swiper-pagination"></div>-->
      </div>
      <section class="swiper-nav" v-if="books.length > 0">
        <div @click="nextSlide(true)" class="swiper-nav__nav-arrow next white--text">
          <i class="fas fa-chevron-right"></i></div>
        <div @click="nextSlide(false)" class="swiper-nav__nav-arrow prev white--text">
          <i class="fas fa-chevron-left"></i></div>
      </section>
      <div class="no-favourites white--text font-weight-bold px-6 py-10" v-else>
        <p>No books found :-(</p>
      </div>
    </div>

    <div v-else class="d-flex justify-center pb-6 pt-15">
      <v-progress-circular :indeterminate="true" :rotate="0" :value="0" color="white"></v-progress-circular>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import {mapState} from 'vuex';
import Swiper from 'swiper/bundle';
import BookService from '../../services/BookService';
import HelperService from '../../services/HelperService';

export default {
  props: {
    filter: {
      required: true,
      type: Object,
    },
    title: {
      required: true,
      type: String,
    }
  },
  components: {},
  computed: {
    ...mapState({
      favourites: state => state.favourites.favourites,
    })
  },
  data() {
    return {
      sliderId: '',
      loading: true,
      swiper: null,
      books: [],
      placeholder: require('../../assets/images/books/placeholder.png'),
      background: require('../../assets/images/background/book-bg-1.png'),
    };
  },
  mounted() {
    this.sliderId = HelperService.getRandomId(8);
    this.getBooksByFilter();
  },
  methods: {
    getBooksByFilter() {
      this.loading = true;
      BookService.getBooks(this.filter).then(async (res) => {
        this.books = res.data.data;
      }).finally(() => {
        this.loading = false;

        Vue.nextTick(() => {
          this.setUpSlides();
        });
      });
    },
    setBookPlaceholder(event) {
      event.target.setAttribute('src', this.placeholder);
    },
    setUpSlides() {
      this.swiper = new Swiper('.' + this.sliderId, {
        grabCursor: true,
        // centeredSlides: true,
        // freeMode: true,
        slidesPerView: 3,
        spaceBetween: 30,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
        },
        // on: {
        //   click: function (swiper, event) {
        //     /* do something */
        //     console.log('clicked slide', swiper, 'e', event);
        //     swiper.slideTo(swiper.clickedIndex);
        //   },
        // }
      });

      setTimeout(() => {
        if (this.swiper) {
          this.swiper.update();
        }
      });
    },
    clickedBook(e, book) {
      if (!e.target.classList.contains('fa-heart')) {
        this.$emit('result', book);
      }
    },
    nextSlide(value) {
      if(value) {
        this.swiper.slideNext();
      } else {
        this.swiper.slidePrev();
      }
    },
  },
};
</script>
