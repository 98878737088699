import axios from 'axios';
const baseElectron ='https://electronref';

export default {
  sendStat(meta, description) { // fake request to send to electron. matches pattern
    return axios.post(`${baseElectron}/electron-log`, this.createBookLogObject(meta, description));
  },
  createBookLogObject(meta, description) {
    return {
      action: "Book Log",
      description: description,
      meta: JSON.stringify(meta),
      target: 'com.madepurple.purplebooks',
      app_id: 'com.madepurple.purplebooks',
    };
  },
  // convertMdmToken(token) {
  //   return axios.post(`${base}mdm-users/tokens`, { token })
  // },
};
