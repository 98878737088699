export default {
  /**
   * Build a query string from a filters object using key value pairs
   * */
  buildSearchQueryString(filters) {
    let query = '?';

    // eslint-disable-next-line no-restricted-syntax
    for (const key in filters) {
      if (filters[key] !== null && filters[key] !== undefined && filters[key] !== '') {
        query += `${key}=${filters[key]}&`;
      }
    }

    query = query.substr(0, query.length - 1); // remove any extra '&' symbols

    return query;
  },
  getPaginatedLength(totalRows, perPage) {
    return Math.ceil(totalRows / perPage);
  },
  getRandomId(length) {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP';
    let pass = '';
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x < length; x++) {
      const i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }

    return pass;
  },
}
